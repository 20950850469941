import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { queryPTR, queryTraceroute } from '../api/ptrApi';
import '../styles/PtrQueryPage.css';
import Navbar from '../components/NavBar';
import L from 'leaflet';


const PtrQueryPage = () => {
    const location = useLocation();
    const [ipAddress, setIpAddress] = useState('');
    const [ptrRecord, setPtrRecord] = useState('');
    const [asn, setAsn] = useState('');
    const [result, setResult] = useState(null);
    const [trData, setTrData] = useState(null);
    const [error, setError] = useState('');
    const [tracerouteOutput, setTracerouteOutput] = useState('');
    const [autoSubmit, setAutoSubmit] = useState(true);
    const [validHops, setValidHops] = useState([]);

    // On page load, populate PTR record and ASN from location.state
    useEffect(() => {
        if (location.state) {
            setPtrRecord(location.state.ptrRecord || '');
            setAsn(location.state.asn || '');
        }
    }, [location.state]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);

        try {
            // API call to query PTR
            const data = await queryPTR(ptrRecord, asn === '' ? 0 : asn, ipAddress);
            setResult(data);
        } catch (err) {
            setError('Failed to fetch data. Please try again.');
        }
    };

    const handleTracerouteSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);
    
        try {
            const data = await queryTraceroute(tracerouteOutput);
            setTrData(data);
            console.log(data);
    
            // Ensure data.traceroute is an array before filtering
            if (Array.isArray(data.traceroute)) {
                const filteredHops = data.traceroute.filter(hop => 
                    hop.ptr && hop.ip
                );
                setValidHops(filteredHops);  // Update state
            } else {
                setError("Invalid traceroute data format");
            }
        } catch (err) {
            console.error(err);
            setError('Failed to parse traceroute. Please try again');
        }
    };

    const renderTracerouteTable = () => {
        if (validHops.length === 0) return null; // Don't render if no valid hops
        
        return (
            <table className="styled-table" style={{ border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Hop Number</th>
                        <th>IP</th>
                        <th>City</th>
                        <th>State/Region</th>
                        <th>Country</th>
                        <th>ASN</th>
                        <th>PTR</th>
                    </tr>
                </thead>
                <tbody>
                    {validHops.map((hop, index) => (
                        <tr key={index}>
                            <td>{hop.hop_num}</td>
                            <td>{hop.ip}</td>
                            <td>{hop.location.city || 'Unknown'}</td>
                            <td>{hop.location.state || hop.location.region || 'N/A'}</td>
                            <td>{hop.location.country || 'N/A'}</td>
                            <td>{hop.asn}</td>
                            <td>{hop.ptr || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const renderWorldMapWithArrows = (mapData) => {
        const map = L.map('map').setView([20, 0], 2);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        const arrowIcon = L.icon({
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',
            iconSize: [25, 25],
        });

        mapData.forEach((hop, index) => {
            if (index < mapData.length - 1) {
                const nextHop = mapData[index + 1];
                const latlngs = [
                    [hop.lat, hop.lng],
                    [nextHop.lat, nextHop.lng]
                ];
                const polyline = L.polyline(latlngs, { color: 'blue' }).addTo(map);
                polyline.setText('   ►   ', { repeat: true, offset: 10, attributes: { fill: 'blue' } });
            }

            L.marker([hop.lat, hop.lng], { icon: arrowIcon }).addTo(map)
                .bindPopup(`<strong>${hop.city}</strong><br />State/Region: ${hop.stateOrRegion}<br />Country: ${hop.country}<br />ASN: ${hop.asn}<br />PTR: ${hop.ptr}`);
        });
    };

    useEffect(() => {
        if (autoSubmit && ((ipAddress) || (ptrRecord && asn))) {
            handleSubmit(new Event('submit'));
        }
    }, [autoSubmit, ptrRecord, asn]);

    // Function to render a simplified table
    const renderTable = () => (
        <table className="styled-table">
            <thead>
                <tr>
                    <th>PTR Record</th>
                    <th>Regular Expression</th>
                    <th>Geo Hint</th>
                    <th>City</th>
                    <th>{result.location_info.region ? 'Region' : 'State'}</th>
                    <th>Country</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{result.ptr_record}</td>
                    <td>{result.regular_expression}</td>
                    <td>{result.geo_hint}</td>
                    <td>{result.location_info.city}</td>
                    <td>{result.location_info.region || result.location_info.state}</td>
                    <td>{result.location_info.country}</td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <div className="ptr-query-page">
            <Navbar>{Navbar()}</Navbar>
            <p>
                Provided either an IP or an ASN and PTR record, we will use our generated regex and hint mappings to extract geographic information from the record.
            </p>
            <form onSubmit={handleSubmit}>
                <input
                    className="input-box"
                    type="text"
                    id="ipAddress"
                    value={ipAddress}
                    onChange={(e) => setIpAddress(e.target.value)}
                    placeholder="Enter IP Address"
                />
                <input
                    className="input-box"
                    type="text"
                    id="ptrRecord"
                    value={ptrRecord}
                    onChange={(e) => setPtrRecord(e.target.value)}
                    placeholder="Enter PTR Record"
                />
                <input
                    className="input-box"
                    type="text"
                    id="asn"
                    value={asn}
                    onChange={(e) => setAsn(e.target.value)}
                    placeholder="Enter ASN Number"
                />

                <button type="submit" className="button-container move-up">Submit</button>
            </form>
            
            {error && <p className="error-message">{error}</p>}

            {result && (
                <div className="table-container">
                    {renderTable()}
                </div>
            )}
                <p>The Aleph can also map out traceroutes, paste your raw output here to see how much of the path the Aleph can map.</p>
                <textarea
                    className="input-box"
                    id="tracerouteOutput"
                    value={tracerouteOutput}
                    onChange={(e) => setTracerouteOutput(e.target.value)}
                    placeholder="Paste Traceroute Output Here"
                    style={{ width: '50%', height: 'auto', resize: 'vertical' }}
                />
                <div style={{ textAlign: 'center' }}>
                    <button type="button" className="button-container move-up" onClick={handleTracerouteSubmit}>Parse Traceroute</button>
                </div>

            {validHops.length > 0 && (
                <div className="table-container">
                    {renderTracerouteTable()}
                </div>
            )}
        </div>
    );
}
export default PtrQueryPage;
